import React from 'react';
import {useTranslation} from 'react-i18next';
import CheckIcon from '../../../common/icons/CheckIcon';
import CloseIcon from '../../../common/icons/CloseIcon';
import {MenuGroup} from '../../../types/mealplan/MenuGroup';

type Props = {
    specialMenuGroupsOpen: Array<MenuGroup>,
    specialMenuGroupsAttest: Array<MenuGroup>,
    onRequest: () => void,
    onDiscard: () => void
}

function ChildSetupSpecialDietYesNo({specialMenuGroupsOpen, specialMenuGroupsAttest, onRequest, onDiscard}: Readonly<Props>) {
    const [t] = useTranslation();

    return <>
        {
            specialMenuGroupsOpen.length > 0 &&
            <>
                <span>{t('ChildSetup.SPECIAL_MEAL_NO_ATTEST')}:</span>
                <ul>
                    {specialMenuGroupsOpen.map(menuGroup => <li key={menuGroup.id}>{menuGroup.name}</li>)}
                </ul>
            </>
        }
        {
            specialMenuGroupsAttest.length > 0 &&
            <>
                <span>{t('ChildSetup.SPECIAL_MEAL_ATTEST_MANDATORY')}:</span>
                <ul>
                    {specialMenuGroupsAttest.map(menuGroup => <li key={menuGroup.id}>{menuGroup.name}</li>)}
                </ul>
            </>
        }

        <div className="d-flex justify-content-end mt-4">
            <button className="btn btn-primary mr-2 px-5" onClick={onDiscard}>
                <CloseIcon/>
                <span className="ml-2">{t('Button.NO')}</span>
            </button>
            <button className="btn btn-primary px-5" onClick={onRequest}>
                <CheckIcon/>
                <span className="ml-2">{t('Button.YES')}</span>
            </button>
        </div>
    </>;
}

export default ChildSetupSpecialDietYesNo;
