import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import accountApi from '../../../account/api/accountApi';
// @ts-ignore
import Sonderkost from '../../../assets/pdf/Sonderkost.pdf';
import {markChanges, unmarkChanges} from '../../../common/action/pageLeaveActions';
import Card from '../../../common/component/Card';
import Checkbox from '../../../common/component/form/Checkbox';
import NextButton from '../../../common/component/NextButton';
import Permission from '../../../common/enums/Permission';
import {UPLOAD_IMAGE_TYPES_ALL} from '../../../common/GlobalConstants';
import CheckIcon from '../../../common/icons/CheckIcon';
import InfoIcon from '../../../common/icons/InfoIcon';
import UploadIcon from '../../../common/icons/UploadIcon';
import {useHasPermission} from '../../../common/util/PermissionUtil';
import {checkValidation} from '../../../common/util/ValidationUtil';
import {showMessage} from '../../../message/action/messageActions';
import {Child} from '../../../types/Child';
import {MenuGroup} from '../../../types/mealplan/MenuGroup';
import '../../style/childSetup/ChildSetup.scss';
import childApi from '../../api/ChildApi';

type Props = {
    accountId: number,
    child: Child,
    specialMenuGroupsOpen: Array<MenuGroup>,
    specialMenuGroupsAttest: Array<MenuGroup>,
    onSelect: (menuGroup: MenuGroup) => void,
    onSkip: () => void
}

function ChildSetupSpecialDietSelection({accountId, child, specialMenuGroupsOpen, specialMenuGroupsAttest, onSelect, onSkip}: Readonly<Props>) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const hasParentPermission = useHasPermission(Permission.WRITE_PARENT);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [selectedMenuGroup, setSelectedMenuGroup] = useState<MenuGroup>();
    const [attest, setAttest] = useState<File>();
    const [isAllergyPdfConfirmed, setIsAllergyPdfConfirmed] = useState<boolean>(false);
    const [validation, setValidation] = useState({
        menuGroup: true,
        attest: true,
        allergyPdfConfirmation: true
    });
    const [showValidationErrors, setShowValidationErrors] = useState(false);

    const validate = useCallback(() => {
        const newValidation = {
            menuGroup: !!selectedMenuGroup,
            attest: !selectedMenuGroup?.nutritionClass.isAttestRequired || !!attest,
            allergyPdfConfirmation: hasParentPermission || isAllergyPdfConfirmed
        };

        setShowValidationErrors(true);
        setValidation(newValidation);

        return checkValidation(newValidation);
    }, [selectedMenuGroup, attest, isAllergyPdfConfirmed, hasParentPermission]);

    useEffect(() => {
        if (showValidationErrors) {
            validate();
        }
    }, [selectedMenuGroup, isAllergyPdfConfirmed, showValidationErrors, validate]);

    const handleMenuGroupChange = useCallback((menuGroup: MenuGroup) => {
        dispatch(markChanges());
        setSelectedMenuGroup(menuGroup);
    }, [dispatch]);

    const handleFileUploadChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            dispatch(markChanges());
            setAttest(event.target.files[0]);
        }
    }, [dispatch]);

    const handleMenuGroupSaved = useCallback((menuGroup: MenuGroup) => {
        dispatch(showMessage('Child.SAVE_SUCCESS'));
        dispatch(unmarkChanges());
        onSelect(menuGroup);
    }, [dispatch, onSelect]);

    const handleConfirmMenuGroup = useCallback(() => {
        if (validate()) {
            const childForUpdateRequest: Partial<Child> = {
                id: child.id,
                accountId,
                firstName: child.firstName,
                lastName: child.lastName,
                // dailyOnSiteOrderLimit must be present in child updates
                // if the child is assigned to an institution that uses on-site order (is ignored otherwise).
                dailyOnSiteOrderLimit: child.dailyOnSiteOrderLimit,
                allergies: [
                    {
                        nutritionClassId: selectedMenuGroup!.nutritionClass.id
                    }
                ]
            };

            // childApi.createOrUpdate also creates the task; add the attest file to the task in the next step (if necessary).
            childApi.createOrUpdate(childForUpdateRequest).then(allergyResponse => {
                if (allergyResponse.data.success) {
                    if (selectedMenuGroup!.nutritionClass.isAttestRequired) {
                        const intoleranceRequestFormData = new FormData();
                        intoleranceRequestFormData.append('accountId', accountId + '');
                        intoleranceRequestFormData.append('childId', child.id + '');
                        intoleranceRequestFormData.append('nutritionClassId', selectedMenuGroup!.nutritionClass.id + '');
                        intoleranceRequestFormData.append('file', attest!);

                        accountApi.uploadProofOfIntolerance(intoleranceRequestFormData).then(taskResponse => {
                            if (taskResponse.data.success) {
                                handleMenuGroupSaved(selectedMenuGroup!);
                            }
                        });
                    } else {
                        handleMenuGroupSaved(selectedMenuGroup!);
                    }
                }
            });
        }
    }, [validate, selectedMenuGroup, attest, accountId, child, handleMenuGroupSaved]);

    return <>
        {
            !hasParentPermission &&
            <>
                <p>
                    {t('ChildSettings.SPECIAL_MEALS_INFO1')}
                    <a href={Sonderkost} target="_blank" rel="noreferrer">{t('ChildSettings.SPECIAL_MEALS_HERE')}</a>
                    {t('ChildSettings.SPECIAL_MEALS_INFO2')}
                </p>
                <div className="d-flex">
                    <div className="icon-primary-not-clickable mr-2"><InfoIcon/></div>
                    <Checkbox
                        value={isAllergyPdfConfirmed}
                        label={t('ChildSettings.CONFIRM_READ_ALLERGY_INFO')}
                        onChange={value => setIsAllergyPdfConfirmed(!!value)}
                        required={true}
                        isValid={!showValidationErrors || validation.allergyPdfConfirmation}
                    />
                    {
                        showValidationErrors && !validation.allergyPdfConfirmation &&
                        <div className="small text-danger mt-1 ml-2">{t('Common.PLEASE_CONFIRM')}</div>
                    }
                </div>
            </>
        }
        <p>{t('ChildSetup.SPECIAL_MEAL_SELECTION.ATTEST_NOTE')}</p>
        {
            showValidationErrors && !validation.menuGroup &&
            <p className="text-error">{t('ChildSetup.MENU_GROUP_MISSING')}</p>
        }
        {
            showValidationErrors && !validation.attest &&
            <p className="text-error">{t('ChildSetup.ATTEST_MISSING')}</p>
        }

        <div className="row">
            {
                specialMenuGroupsOpen.map(menuGroup =>
                    <div key={menuGroup.id} className="col-6 mb-4">
                        <Card
                            className={`menu-group-selection-card ${selectedMenuGroup?.id === menuGroup.id ? 'active' : ''}`}
                            onClick={() => handleMenuGroupChange(menuGroup)}
                        >
                            <div className="d-flex justify-content-between">
                                <b>{menuGroup.name}</b>
                                {selectedMenuGroup?.id === menuGroup.id && <div className="text-primary"><CheckIcon/></div>}
                            </div>
                        </Card>
                    </div>)
            }
            {
                specialMenuGroupsAttest.map(menuGroup =>
                    <div key={menuGroup.id} className="col-6 mb-4">
                        <Card
                            className={`menu-group-selection-card ${selectedMenuGroup?.id === menuGroup.id ? 'active' : ''}`}
                            onClick={() => handleMenuGroupChange(menuGroup)}
                        >
                            <div className="d-flex justify-content-between">
                                <div><b>{menuGroup.name}</b> <span className="text-grey">- {t('FoodRestriction.NEED_ATTEST')}</span></div>
                                {selectedMenuGroup?.id === menuGroup.id && <div className="text-primary"><CheckIcon/></div>}
                            </div>
                        </Card>
                    </div>)
            }
        </div>

        <input
            type="file"
            className="d-none"
            ref={fileInputRef}
            accept={UPLOAD_IMAGE_TYPES_ALL}
            onChange={handleFileUploadChange}
        />

        <button
            className="btn btn-secondary"
            onClick={event => {
                event.preventDefault();
                fileInputRef.current?.click()
            }}
            disabled={!selectedMenuGroup?.nutritionClass.isAttestRequired}
        >
            <UploadIcon/>
            <span className="ml-2">{t('FoodRestriction.UPLOAD_HERE')}</span>
        </button>
        {
            attest &&
            <div className="d-inline-flex ml-3 font-weight-bold">{t('FileUpload.CHOSEN_FILE')}:&nbsp;<i>{attest.name}</i></div>
        }

        <div className="d-flex justify-content-end mt-4">
            <button className="btn btn-secondary mr-2" onClick={onSkip}>{t('ChildSetup.SKIP_SPECIAL_MEAL')}</button>
            <NextButton onClick={handleConfirmMenuGroup}/>
        </div>
    </>;
}

export default ChildSetupSpecialDietSelection;
