import React from 'react';
import {useTranslation} from 'react-i18next';
import FormButtons from '../../../common/component/form/FormButtons';
import {Child} from '../../../types/Child';

type Props = {
    child: Partial<Child>,
    onProceed: () => void,
    onSkip: () => void
}

function ChildSetupSubscriptionYesNo({child, onProceed, onSkip}: Readonly<Props>) {
    const [t] = useTranslation();

    return <div className="card">
        <div className="card-header">
            <p className="text-grey" style={{fontSize: '16px', fontWeight: 'normal'}}>{t('Common.RECOMMENDED')}</p>
            {t('ChildSetup.SUBSCRIPTION.HEADER_1', {name: child.firstName})}
        </div>
        <div className="card-body">
            <p className="pre-wrap">
                {t('ChildSetup.SUBSCRIPTION.INTRO')}
            </p>
            <FormButtons
                saveLabelKey='Subscription.SETUP'
                onSave={onProceed}
                cancelLabelKey='Button.NOT_NOW'
                onCancel={onSkip}
                showCancelIcon={false}
                sticky={false}
            />
        </div>
    </div>;
}

export default ChildSetupSubscriptionYesNo;
