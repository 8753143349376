import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {unmarkChanges} from '../../../common/action/pageLeaveActions';
import PageLeaveGuard from '../../../common/component/PageLeaveGuard';
import CloseIcon from '../../../common/icons/CloseIcon';
import {DefaultState} from '../../../common/reducer/reducers';
import mealPlanManagementApi from '../../../mealplan/api/mealPlanManagementApi';
import {Child} from '../../../types/Child';
import {MenuGroup} from '../../../types/mealplan/MenuGroup';
import ChildSetupBasics from './ChildSetupBasics';
import ChildSetupSpecialDiet from './ChildSetupSpecialDiet';
import ChildSetupSubscriptionWrapper from './ChildSetupSubscriptionWrapper';

type Props = {
    catererId: number,
    accountId: number,
    onSaveDone: () => void,
    onCancel: () => void
}

function ChildSetup({catererId, accountId, onSaveDone, onCancel}: Readonly<Props>) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const hasChanges = useSelector((state: DefaultState) => state.pageLeave.hasUnsavedChanges);

    const [child, setChild] = useState<Child>();
    const [nextStage, setNextStage] = useState('basics');
    const [specialMenuGroupsOpen, setSpecialMenuGroupsOpen] = useState<Array<MenuGroup>>([]);
    const [specialMenuGroupsAttest, setSpecialMenuGroupsAttest] = useState<Array<MenuGroup>>([]);

    const handleProceedFromBasics = useCallback((child: Child) => {
        setChild(child);
        mealPlanManagementApi.fetchMenuGroupsForInstitution(child.group.institutionId, false, false, true).then(response => {
            if (response.data.success) {
                const menuGroups: Array<MenuGroup> = response.data.result;

                const openMenuGroupsTemp: Array<MenuGroup> = [];
                menuGroups
                    .filter(menuGroup => menuGroup.nutritionClass && !menuGroup.nutritionClass.isAttestRequired)
                    .forEach(menuGroup => openMenuGroupsTemp.push(menuGroup));
                setSpecialMenuGroupsOpen(openMenuGroupsTemp);

                const attestMenuGroupsTemp: Array<MenuGroup> = [];
                menuGroups
                    .filter(menuGroup => menuGroup.nutritionClass?.isAttestRequired)
                    .forEach(menuGroup => attestMenuGroupsTemp.push(menuGroup));
                setSpecialMenuGroupsAttest(attestMenuGroupsTemp);

                if (openMenuGroupsTemp.length > 0 || attestMenuGroupsTemp.length > 0) {
                    setNextStage('special-meal');
                } else {
                    setNextStage('subscription');
                }
            }
        });
    }, []);

    const handleProceedFromSpecialMeal = useCallback((menuGroup: MenuGroup | null) => {
        if (menuGroup?.nutritionClass.isAttestRequired) {
            setNextStage('welfare');
        } else {
            setNextStage('subscription');
        }
    }, []);

    const handleCancel = useCallback(() => {
        dispatch(unmarkChanges());
        onCancel();
    }, [dispatch, onCancel]);

    return <>
        <h1>
            <div className="d-flex justify-content-between">
                {t('Parent.ADD_CHILD')}
                <button className="btn btn-transparent" onClick={handleCancel}>
                    <CloseIcon/>
                    <span className="ml-2">{t('Button.CLOSE')}</span>
                </button>
            </div>
        </h1>
        {
            nextStage === 'basics' &&
            <ChildSetupBasics
                catererId={catererId}
                accountId={accountId}
                onProceed={handleProceedFromBasics}
                onSaveDone={onSaveDone}
            />
        }

        {
            nextStage === 'special-meal' && child &&
            <ChildSetupSpecialDiet
                accountId={accountId}
                child={child}
                specialMenuGroupsOpen={specialMenuGroupsOpen}
                specialMenuGroupsAttest={specialMenuGroupsAttest}
                onProceed={handleProceedFromSpecialMeal}
            />
        }

        {
            nextStage === 'subscription' && child &&
            <ChildSetupSubscriptionWrapper
                catererId={catererId}
                accountId={accountId}
                child={child}
                onProceed={() => setNextStage('welfare')}
                onSkip={() => {dispatch(unmarkChanges()); setNextStage('welfare');} }
            />
        }

        {
            nextStage === 'welfare' && child &&
            'TODO: Welfare for ' + child.firstName
        }
        <PageLeaveGuard hasChanges={hasChanges}/>
    </>;
}

export default ChildSetup;
